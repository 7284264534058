import { useEffect, useState } from 'react';
import { LeadDetails } from '@/components/3-organisms';
import { LeadsLayout } from '@/components/4-templates';
import { useAppDispatch, useAppSelector } from '@/store';
import { setTitle } from '@/store/slices/headerSlice';
import { useParams, useNavigate } from 'react-router-dom';
import { participateInTender } from '@/services/TenderServices';
import styles from './LeadDetailsPage.module.scss';
import { GetLeadsOverview } from '@/services/LeadServices';

export const LeadDetailsPage = () => {
  const { leadId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [leadsOverview, setLeadsOverview] = useState<LeadOverview>();

  useEffect(() => {
    async function getLeads() {
      const response = await GetLeadsOverview();
      setLeadsOverview(response);
    }
    dispatch(setTitle('leads'));
    getLeads();
  }, []);

  const lead = leadsOverview?.leads?.find((lead) => lead.id === parseInt(leadId ?? '0'));
  const orgId = useAppSelector((state) => state.header?.activeOrg?.id);

  useEffect(() => {
    dispatch(setTitle(lead?.name || 'Lead details'));
  }, [dispatch, lead]);

  const handleActionTriggered = async () => {
    if (!lead?.tenderId || !orgId) {
      console.error('Required information missing to participate in tender.');
      return;
    }

    try {
      await participateInTender(orgId, lead.tenderId);
      await navigate(`/leads/tendersOverview/${lead.tenderId}/tenderDetails`);
    } catch (error) {
      console.error('Failed to participate in tender:', error);
    }
  };

  return (
    <LeadsLayout>
      <section className={styles.LeadDetailsPage}>
        <LeadDetails
          className={styles.LeadDetails}
          lead={lead}
          onActionTriggered={handleActionTriggered}
        />
      </section>
    </LeadsLayout>
  );
};
