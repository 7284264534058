import { LeadsLayout } from '@/components/4-templates';
import { useAppDispatch, useAppSelector } from '@/store';
import { setTitle } from '@/store/slices/headerSlice';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';

import styles from './TenderDetailsPage.module.scss';
import { useEffect, useState } from 'react';
import { TopNavigation } from '@/components/2-molecules';
import { getSupplierTender } from '@/services/TenderServices';
import {
  ChatList,
  ChatRoomDetails,
  Details,
  NoticeBoard,
  ResponsesList,
} from '@/components/3-organisms';
import { LoadingSpinner } from '@/components/1-atoms';

export const TenderDetailsPage = () => {
  const { tenderId } = useParams();
  const dispatch = useAppDispatch();

  const [tenderDetails, setTenderDetails] = useState<TenderDetails | null>(null);

  const contactId = useAppSelector((state) => state.header.peripheralOverview.contactId);

  useEffect(() => {
    if (tenderId) fetchTenderDetails();
  }, [tenderId]);

  const fetchTenderDetails = async () => {
    const details = await getSupplierTender(Number(tenderId));
    setTenderDetails(details);
    dispatch(setTitle(details.details.name));
  };

  return (
    <LeadsLayout>
      <section className={styles.TenderDetailsPage}>
        <TopNavigation
          navigationList={[
            { label: 'Tender details', url: 'tenderDetails' },
            { label: 'Tender responses', url: 'tenderResponses' },
            { label: 'Notice board', url: 'noticeBoard' },
            { label: 'Chat', url: '/leads/tendersOverview/' + tenderId + '/chat' },
          ]}
        />

        {!tenderDetails ? (
          <LoadingSpinner className={styles.TenderDetailsPage__spinner} />
        ) : (
          <Routes>
            <Route path='/' element={<Navigate to={'tenderDetails'} />} />
            <Route path='tenderDetails' element={<Details tender={tenderDetails} />} />
            <Route
              path='tenderResponses'
              element={<ResponsesList phases={tenderDetails?.details.phases} />}
            />
            <Route
              path='noticeBoard'
              element={<NoticeBoard notices={tenderDetails?.noticeBoard?.alerts} />}
            />
            <Route
              path='chat'
              element={
                <ChatList
                  chatRooms={tenderDetails.chatRooms}
                  userRole='supplier'
                  contactId={contactId}
                />
              }
            />

            <Route
              path='chat/:roomId'
              element={<ChatRoomDetails userRole='supplier' contactId={contactId} />}
            />
          </Routes>
        )}
      </section>
    </LeadsLayout>
  );
};
