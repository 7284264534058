import { useEffect, useState } from 'react';

import { useAppDispatch } from '@/store';
import { setTitle } from '@/store/slices/headerSlice';

import { TendersOverview } from '@/components/3-organisms';
import { TendersLayout } from '@/components/4-templates';

import styles from './TendersOverviewPage.module.scss';
import { GetBuyerTenderOverview } from '@/services/TenderServices';

export const TendersOverviewPage = () => {
  const dispatch = useAppDispatch();
  const [tendersList, setTendersList] = useState<Tender[]>([]);

  useEffect(() => {
    async function getTenders() {
      const response = await GetBuyerTenderOverview();
      setTendersList(response?.tenders);
    }
    dispatch(setTitle('tenders'));
    getTenders();
  }, []);

  return (
    <TendersLayout>
      <section className={styles.TendersOverviewPage}>
        <TendersOverview tendersList={tendersList} />
      </section>
    </TendersLayout>
  );
};
