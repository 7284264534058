import Api from '../axiosApi';

export const CreateOrganisation = (
  userId: number,
  organisationNo: string,
  organisationType: string,
  organisationJurisdictionId = 1,
  roaringEnabled = true,
): Promise<OrganisationDetails> => {
  return Api().post('/Organisation/CreateOrganisation', {
    userId: userId,
    organisationNo: organisationNo,
    organisationType: organisationType,
    organisationJurisdictionId: organisationJurisdictionId,
    roaringEnabled: roaringEnabled,
  });
};

export const GetAllCountryCodes = (): Promise<any> => {
  return Api().get('/Organisation/GetAllOrganisationJurisdictions');
};

export const UpdateOrganisation = (
  organisation: OrganisationDetails,
): Promise<{ successfulOperation: boolean }> => {
  return Api().post('/Organisation/UpdateOrganisation', {
    ...organisation,
  });
};

export const SwapActiveOrganisation = (
  userId: number,
  orgId: number,
): Promise<{ successfulOperation: boolean }> => {
  return Api().post('/Organisation/SwapActiveOrganisation', {
    userId: userId,
    orgId: orgId,
  });
};
