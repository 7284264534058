import { GetPeripheralOverview } from '@/services/PeripheralServices';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface HeaderState {
  title: string;
  peripheralOverview: PeripheralOverview;
  activeOrg: Organisation | undefined;
}
const initialState: HeaderState = {
  title: 'overview',
  peripheralOverview: { contactId: NaN, fullName: '', organisations: [], notifications: [] },
  activeOrg: undefined,
};

export const getPeripheral = createAsyncThunk('header/GetPeripheralOverview', async () => {
  return await GetPeripheralOverview();
});

export const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    setTitle: (state: HeaderState, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPeripheral.fulfilled, (state, action) => {
      const activeOrg = action.payload.organisations?.find((org) => org?.active);
      state.activeOrg = activeOrg;
      state.peripheralOverview = action.payload;
    });
  },
});

export const { setTitle } = headerSlice.actions;
export default headerSlice.reducer;
