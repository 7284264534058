import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '@/store';
import { setTitle } from '@/store/slices/headerSlice';

import { LeadsOverview } from '@/components/3-organisms';
import { LeadsLayout } from '@/components/4-templates';

import styles from './LeadsOverviewPage.module.scss';
import { GetLeadsOverview } from '@/services/LeadServices';

export const LeadsOverviewPage = () => {
  const dispatch = useAppDispatch();

  const [leadsOverview, setLeadsOverview] = useState<LeadOverview>();

  useEffect(() => {
    async function getLeads() {
      const response = await GetLeadsOverview();
      setLeadsOverview(response);
    }
    dispatch(setTitle('leads'));
    getLeads();
  }, []);

  return (
    <LeadsLayout>
      <section className={styles.LeadsOverviewPage}>
        <LeadsOverview leadsList={leadsOverview} />
      </section>
    </LeadsLayout>
  );
};
