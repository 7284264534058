import { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import classNames from 'classnames';

import { Heading } from '@/components/1-atoms';
import { LoadingCard, PopupCard } from '@/components/2-molecules';

import { CreateOrganisationStep } from './CreateOrganisationStep';
import { UpdateOrganisationStep } from './UpdateOrganisationStep';

import styles from './CreateOrganisationForm.module.scss';

type currentStep = 'createOrganisation' | 'updateOrganisation';

export interface CreateOrganisationFormProps {
  className?: string;
  createOrganisation: (data: OrganisationDetails) => Promise<OrganisationDetails>;
  updateOrganisation: (data: OrganisationDetails) => void;
}

export const CreateOrganisationForm = ({
  className,
  createOrganisation,
  updateOrganisation,
}: CreateOrganisationFormProps) => {
  const [currentStep, setCurrentStep] = useState<currentStep>('createOrganisation');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const methods = useForm<OrganisationDetails>({ mode: 'onTouched' });
  const { handleSubmit, setValue } = methods;

  const onSubmit = async (data: OrganisationDetails) => {
    if (currentStep === 'createOrganisation') {
      setIsLoading(true);
      const organisation = await createOrganisation(data);
      await autoFillFormData(organisation);
      await setCurrentStep('updateOrganisation');
      await setIsLoading(false);
    } else {
      data.invoicesInLastYear = Number(data?.invoicesInLastYear ?? 0);
      data.invoicesOutLastYear = Number(data?.invoicesOutLastYear ?? 0);
      await updateOrganisation(data);
    }
  };

  const autoFillFormData = (data: OrganisationDetails) => {
    setValue('userId', data.userId);
    setValue('orgId', data.orgId);
    setValue('companyName', data.companyName);
    setValue('industry', data.industry);
    setValue('organisationNo', data.organisationNo);
    setValue('contact.id', data.contact.id);
    setValue('contact.firstName', data.contact.firstName);
    setValue('contact.lastName', data.contact.lastName);
    setValue('contact.phoneNo', data.contact.phoneNo);
    setValue('contact.email', data.contact.email);
    setValue('organisationEmail', data.organisationEmail);
    setValue('address', data.address);
    setValue('invoicesInLastYear', data.invoicesInLastYear);
    setValue('invoicesOutLastYear', data.invoicesOutLastYear);
    // setValue('numberOfEmployees', data.numberOfEmployees);
    // setValue('turnover', data.turnover);
  };

  return (
    <section className={classNames(styles.CreateOrganisationForm, className)}>
      <FormProvider {...methods}>
        <form className={styles.CreateCompanyForm__form} onSubmit={handleSubmit(onSubmit)}>
          {isLoading ? (
            <LoadingCard>
              <Heading headingLevel={'h3'}>
                We are fetching your data. <br />
                Please wait
              </Heading>
            </LoadingCard>
          ) : (
            <PopupCard className={styles.CreateOrganisationForm__popup} smallSize>
              {currentStep == 'createOrganisation' ? (
                <CreateOrganisationStep />
              ) : (
                <UpdateOrganisationStep />
              )}
            </PopupCard>
          )}
        </form>
      </FormProvider>
    </section>
  );
};
