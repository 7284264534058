import { AnyAction, combineReducers } from 'redux';
import headerSlice, { HeaderState } from './slices/headerSlice';
import userLoginSlice, { UserLoginState } from './slices/userLoginSlice';
import contractsSlice, { ContractsState } from './slices/contractsSlice';
import responsesSlice, { ResponsesState } from './slices/responsesSlice';
import suppliersSlice, { SuppliersState } from './slices/suppliersSlice';
import createTenderSlice from './slices/createTenderSlice';
import pageStatusSlice, { PageState } from './slices/PageStatusSlice';

export interface State {
  header: HeaderState;
  user: UserLoginState;
  pageStatus: PageState;
  createTender: TenderForm;
  responses: ResponsesState;
  contracts: ContractsState;
  suppliers: SuppliersState;
}

const rootReducer = combineReducers({
  header: headerSlice,
  user: userLoginSlice,
  pageStatus: pageStatusSlice,
  createTender: createTenderSlice,
  responses: responsesSlice,
  contracts: contractsSlice,
  suppliers: suppliersSlice,
});

export default rootReducer;
