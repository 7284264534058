import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '@/store';
import { setTitle } from '@/store/slices/headerSlice';

import { Paragraph } from '@/components/1-atoms';
import { WelcomeCard } from '@/components/2-molecules';
import { TasksCalendar, TasksOverview } from '@/components/3-organisms';
import { LeadsLayout } from '@/components/4-templates';

import styles from './DashBoardPage.module.scss';
import { GetDashboardOverview } from '@/services/DashboardServices';

export const DashBoardPage = () => {
  const dispatch = useAppDispatch();
  const [dashboardData, setDashboardData] = useState<DashboardOverview>();

  useEffect(() => {
    async function getDashboard() {
      const response = await GetDashboardOverview();
      setDashboardData(response);
    }
    dispatch(setTitle('overview'));
    getDashboard();
  }, []);

  const firstName = useAppSelector((state) => state.user.loginProfile?.user?.firstName);

  const { numberOfNewMessages, numberOfExpiredContracts, numberOfNewResponses, tasks } =
    dashboardData ?? {};

  return (
    <LeadsLayout>
      <section className={styles.DashBoardPage}>
        <>
          <WelcomeCard title={`Welcome ${firstName}!`}>
            <Paragraph className={styles.WelcomeCard__content}>
              {numberOfNewMessages && `You have ${numberOfNewMessages} new messages`}
              {numberOfExpiredContracts && (
                <>
                  ,
                  <br />
                  You have {numberOfExpiredContracts} contracts expiring
                </>
              )}
              {numberOfNewResponses && (
                <>
                  ,
                  <br />
                  You have {numberOfNewResponses} new responses
                </>
              )}
            </Paragraph>
          </WelcomeCard>

          <div className={styles.DashBoardPage__eventsSection}>
            <TasksOverview tasks={tasks} />

            <TasksCalendar tasks={tasks} className={styles.DashBoardPage__calendar} />
          </div>
        </>
      </section>
    </LeadsLayout>
  );
};
